export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_SUCCESS_UPDATE_USER = "@@auth/API_UPDATE_USER",
  API_RESPONSE_ERROR = "@@auth/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/LOGIN_USER",
  LOGOUT_USER = "@@auth/LOGOUT_USER",
  SIGNUP_USER = "@@auth/SIGNUP_USER",

  CHANGE_PASSWORD = "@@auth/CHANGE_PASSWORD",

  CHANGE_PASSWORD_FORGET = "@@auth/CHANGE_PASSWORD_FORGET",

  SEND_EMAIL_FORGET = "@@auth/SEND_EMAIL_FORGET",

  FORGOT_PASSWORD = "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_CHANGE = "@@auth/FORGOT_PASSWORD_CHANGE",

  RESET = "@@auth/RESET",
}
