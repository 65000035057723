import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
  const baseUrl = "/login";
  return api.create(`${baseUrl}`, params);
}

function changePassword(params: { password: string }) {
  const baseUrl = "/user/change-password";
  return api.update(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/logout";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password";
  return api.create(`${baseUrl}`, params);
}

function forgotPasswordEmail(email: string) {
  const params = { email };
  const baseUrl = "/forget-password";
  return api.create(`${baseUrl}`, params);
}

function changePasswordForgetUser(password: string, uuid: string) {
  const params = { password, uuid };

  const baseUrl = "/change-password-forget";

  return api.update(`${baseUrl}`, params);
}

export {
  login,
  changePassword,
  logout,
  signup,
  forgotPassword,
  forgotPasswordEmail,
  changePasswordForgetUser,
};
