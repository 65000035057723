import Routes from './routes/Routes';

import './assets/scss/Default.scss';

const App = () => {
    return (
        <>
            <Routes />
        </>
    );
};

export default App;
