import { Redirect } from 'react-router-dom';

const Root = () => {

    const getRootUrl = () => {
        let url: string = 'dashboard';
        
        return url;
    };

    const url = getRootUrl();

    return <Redirect to={`/${url}`} />;
};

export default Root;
